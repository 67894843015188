/* Work */

.project-hero {
    background-color: var(--light-border-colour);
    height: auto;
    overflow: hidden;
    img {
        height: 100%;
        object: {
            fit: cover;
            position: center center;
        }
    }
}

.project-nav {
    background-color: var(--colour-primary);
    color: #fff;
    padding: 20px;
    top: -1px;
    &-inner {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: space-between;
        h6 {
            margin-bottom: 0;
            margin-right: 30px;
            a {
                color: var(--bg-colour);
                font-weight: 500;
                .svg-inline--fa {
                    margin-right: 10px;
                }
            }
        }
        ul {
            display: flex;
            @extend %unstyled-list;
            flex-wrap: wrap;
            justify-content: space-around;
            li {
                a {
                    color: var(--bg-colour);
                    display: block;
                    @include convert-to-rem( 16, 500 );
                    margin: 0 30px;
                }
                &.is-current {
                    a {
                        border-bottom: 2px dashed;
                    }
                }
            }
        }
    }
}

@media (max-width:991.98px) {
    .project-nav {
        &-inner {
            h6 {
                span {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: 767.98px) {
    .project-nav {
        display: none;
    }
}

.projects-grid-container {
    display: grid;
    gap: 20px 20px;
    grid-template-columns: repeat(2, 1fr);
    .project-item {
        @extend %subtle-shadow-alt;
        &-img {
            background-color: var(--light-border-colour);
            @include quint-transition(300ms);
            &:hover {
                opacity: 0.8;
            }
        }
        &-info {
            padding: 30px;
            h3 {
                a {
                    color: var(--colour-secondary);
                    @include convert-to-rem( 25, 700 );
                    margin-bottom: 5px;
                }
            }
            .categories {
                display: flex;
                flex-wrap: wrap;
                @extend %unstyled-list;
                li {
                    margin-bottom: 10px;
                    margin-right: 10px;
                    &:last-child {
                        margin-right: 0;
                    }
                    span {
                        background-color: transparent;
                        border: 1px solid var(--colour-secondary);
                        border-radius: 30px;
                        color: var(--colour-secondary);
                        display: block;
                        @include convert-to-rem( 16, 500 );
                        padding: 5px 20px;
                    }
                }
            }
        }
    }
}

@media(max-width:1023.98px) {
    .projects-grid-container {
        grid-template-columns: repeat(1, 1fr);
        .project-item {
            &-info {
                .categories {
                    li {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}

@media(max-width:767.98px) {
    .projects-grid-container {
        .project-item {
            &-info {
                padding: 20px;
                h3 {
                    a {
                        @include convert-to-rem( 20 );   
                    }
                }
            }
        }
    }
}

.project-info {
    border: {
        bottom: 1px solid var(--light-border-colour);
        top: 1px solid var(--light-border-colour);
    }
    display: grid;
    @include convert-to-rem( 16 );
    gap: 20px 20px;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 30px;
    padding: 30px 0;
    &-item {
        h5 {
            @include convert-to-rem( 16, 700 );
            margin-bottom: 8px;
            text-transform: uppercase;
        }
        ul {
            @extend %unstyled-list;
        }
    }
}

@media (max-width:767.98px) {
    .project-info {
        display: block;
        padding: 20px 0 0;
        &-item {
            margin-bottom: 15px;
        }
    }
}

.centered-image {
    display: block;
    margin: 0 auto;
}

.image-grid {
    display: grid;
    gap: 20px 20px;
    &.grid-1 {
        grid-template-columns: repeat(1, 1fr);
        .image-grid-item {
            display: block;
            margin: 0 auto;
        }
    }
    &.grid-2 {
        grid-template-columns: repeat(2, 1fr);
    }
    &.grid-3 {
        grid-template-columns: repeat(3, 1fr);
    }
    &-item {
        background-color: #fff;
        button {
            cursor: zoom-in;
        }
        img {
            border: 1px solid var(--light-border-colour);
        }
        &-caption {
            background-color: #fff;
            color: var(--font-colour);
            padding: 4px 0;
            text-align: center;
            p {
                @include convert-to-rem( 14, 700 );
                margin-bottom: 0;
            }
        }
    }
}

button[aria-label='Unzoom image'] {
    cursor: zoom-out;
}

@media (max-width:767.98px) {
    .image-grid {
        display: block;
    }
}

.table {
    tr {
        td, th {
            @include convert-to-rem( 14 );
        }
    }
}

.split-list {
    display: flex;
}

.quotes {
    display: grid;
    gap: 20px 20px;
    grid-template-columns: repeat(2,1fr);
    .quote {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @extend %subtle-shadow-alt;
        padding: 30px;
        position: relative;
        user-select: none;
    }
}

@media (max-width: 1023.98px) {
    .quotes {
        display: block;
        .quote {
            margin-bottom: 20px;
        }
    }
}