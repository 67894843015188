/* Global Styles */

html {
    scroll-behavior: smooth;
}

body {
    background-color: var(--bg-colour);
    color: var(--font-colour);
    font-family: $body-font;
    @include convert-to-rem( 18, 400 );
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

@media (min-width:1400px) {
    .container {
        max-width: 1200px;
    }
}

img {
    height: auto;
    max-width: 100%;
}

.content-section {
    padding: 60px 0;
    &-large {
        background-color: var(--mn-aliceblue);
        margin-bottom: 60px;
        padding: 100px 0;
    }
}

@media (max-width:1023.98px) {
    .content-section {
        padding: 30px 0;
    } 
}

.subtle-shadow {
    @extend %subtle-shadow-alt;
}

.content-404 {
    text-align: center;
    .svg-inline--fa {
        border: 6px solid var(--mn-red);
        border-radius: 50%;
        color: var(--mn-red);
        height: 100px;
        margin-bottom: 15px;
        padding: 10px;
        width: 100px;
    }
}