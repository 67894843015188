/* Footer */

footer {
    background-color: var(--mn-aliceblue);
    //border-top: 1px solid var(--light-border-colour);
    color: var(--font-colour);
    padding: 60px 0;
    text-align: center;
    h4 {
        font-weight: 600;
    }
    .social-icons {
        @extend %unstyled-list;
        margin: 20px 0;
        display: flex;
        justify-content: center;
        li {
            margin-right: 10px;
            &:last-child {
                margin-right: 0;
            }
            a {
                align-items: center;
                background-color: var(--font-colour);
                color: var(--bg-colour);
                border-radius: 50%;
                display: flex;
                height: 40px;
                flex-direction: column;
                justify-content: center;
                width: 40px;
                &:hover {
                    background-color: var(--colour-primary);
                }
            }
        }
    }
    .colophon {
        @include convert-to-rem( 16 );
        margin-bottom: 0;
    }
    .back-to-top {
        background-color: var(--colour-primary);
        color: #fff;
        height: 50px;
        @include convert-to-rem( 20 );
        width: 50px;
    }
}

@media (max-width:767.98px) {
    footer {
        .colophon {
            @include convert-to-rem( 16 );
        }
    }
}