/* Homepage */

.section-intro {
    .profile-img {
        img {
            border-radius: 10px;
            box-shadow: 0 10px 60px 0 rgba(0,0,0,.2);
            display: block;
            height: 471px;
            object: {
                fit: cover;
                position: center center;
            }
        }
    }
    
    .intro-content {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
    }
}

@media(max-width:1023.98px) {
    .section-intro {
        .profile-img {
            img {
                height: 250px;
                margin: 0 auto 20px;
                object-position: top center;
                width: 250px;
            }
        }
    }
}


.section-skills {
    background-color: var(--bg-colour);
    .skills-container {
        display: grid;
        gap: 30px 20px;
        grid-template-columns: repeat(3, 1fr);
        .skill-list {
            @extend %subtle-shadow-alt;
            padding: 20px;
            &-title {
                color: var(--colour-secondary);
                @include convert-to-rem( 18, 700 );
                text-transform: uppercase;
            }
            ul {
                @extend %unstyled-list;
            }
        }
    }
}

@media (max-width:1023.98px) {
    .section-skills {
        .skills-container {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

.section-exp {
    background-color: var(--bg-colour);
    .exp-container {
        display: grid;
        gap: 20px 20px;
        grid-template-columns: repeat(2, 1fr);
        .exp-item {
            @extend %subtle-shadow-alt;
            padding: 20px;
            h3 {
                @include convert-to-rem( 20 );
            }
            h6 {
                color: var(--colour-secondary);
                font-weight: 700;
                margin-bottom: 5px;
                a {
                    color: var(--colour-secondary);
                }
            }
            .exp-period, .exp-source {
                align-items: center;
                display: flex;                
                //@include convert-to-rem( 16, 500 );
                margin-bottom: 10px;
                img {
                    margin-right: 10px;
                    max-width: 30px;
                }
            }
            .exp-location {
                display: block;
                @include convert-to-rem( 15 );
            }
            .exp-link {
                align-items: center;
                color: var(--colour-secondary);
                display: flex;
                @include convert-to-rem( 15, 700 );
                .svg-inline--fa {
                    @include convert-to-rem( 12 );
                    margin-left: 5px;
                    vertical-align: baseline;
                }
            }
            span {
                &.exp-link {
                    color: var(--mn-red);
                }
            }
        }
    }
}

@media (max-width:1023.98px) {
    .section-exp {
        .exp-container {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

@media (max-width:767.98px) {
    .section-exp {
        .exp-container {
            .exp-item {
                .exp-source {
                    align-items: flex-start;
                }
            }
        }
    }
}