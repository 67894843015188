/* Helpers */

$logo-font: 'Cormorant Garmamond', serif;
$body-font: 'DM Sans', sans-serif;

@mixin convert-to-rem( $pixels, $weight:null ) {
    font: {
        size: $pixels + px;
        size: ($pixels / 16) + rem;
        weight: $weight;
    }
    line-height: ($pixels + 10) + px;
}

%unstyled-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

%subtle-shadow {
    box-shadow: 0 2px 4px rgba(var(--font-colour-rgb),.08);
}

%subtle-shadow-alt {
    box-shadow: 0 10px 60px 0 rgba(var(--font-colour-rgb),.08);
}

@mixin quint-transition( $duration ) {
    transition: all $duration cubic-bezier(0.230, 1.000, 0.320, 1.000); /* easeOutQuint */
}

@mixin font-face( $family, $url, $weight ) {
    @font-face {
        font-family: $family;
        src: url($url);
        font-weight: $weight;
    }
}