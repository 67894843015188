/* Typography */

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
    letter-spacing: 0.3px;
}

p {
    &.lead {
        font-weight: 400;
        strong {
            font-weight: 700;
        }
    }
    a {
        border-bottom: 1px dashed;
    }
}

@media (max-width:767.98px) {
    p {
        &.lead {
            @include convert-to-rem( 18 );
        }
    }
}

a {
    color: var(--colour-secondary);
    font-weight: 700;
    text-decoration: none;
    &:hover {
        color: var(--colour-primary);
    }
    &.styled-link {
        border-bottom: none;
        color: var(--colour-primary);
        display: inline-block;
        font-weight: 700;
        text-decoration: none;
        &::after {
            background-color: var(--colour-primary);
            content: "";
            display: block;
            height: 5px;
            margin-top: 8px;
            @include quint-transition(300ms);
            width: 50%;
        }
        &:hover {
            color: var(--colour-primary);
            &::after {
                width: 100%;
            }
        }
        &-full {
            &::after {
                width: 100%;
            }
        }
    }
}

.page-title {
    color: var(--colour-primary);
    @include convert-to-rem( 50, 700 );
    letter-spacing: 1px;
    margin-bottom: 28px;
    &.project-title {
        margin-bottom: 5px;
    }
    span {
        a {
            color: var(--colour-primary);
            border-bottom: 1px dashed;
            font-weight: 500;
        }
    }
}

@media(max-width:1023.98px) {
    .page-title {
        @include convert-to-rem( 25 );
        margin-bottom: 25px;
    }
}

.section-title {
    color: var(--colour-primary);
    @include convert-to-rem( 30, 700 );
    letter-spacing: 1px;
    margin-bottom: 20px;
}

@media(max-width:1023.98px) {
    .section-title {
        @include convert-to-rem( 25 );
        margin-bottom: 25px;
    }
}

.font-weight-semibold {
    font-weight: 700;
}

blockquote {
    background-color: #f7f7f7;
    border-left: 4px solid var(--colour-primary);
    p {
        @include convert-to-rem( 16, 500 );
    }
}