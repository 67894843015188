/* Header */

header {
    background-color: var(--bg-colour);
    position: relative;
}

.navbar-top {
    .navbar-brand {
        color: var(--colour-primary);
        font-family: $logo-font;
        @include convert-to-rem( 40, 700 );
        @include quint-transition(300ms);
        &:hover {
            color: var(--colour-primary);
        }
    }    
    .navbar-nav {
        .nav-item {
            .nav-link {
                color: var(--font-colour);
                @include convert-to-rem( 15, 700 );
                padding: 5px 15px;
                .menu-icon {
                    margin-left: 6px;
                    @include convert-to-rem( 13 );
                    vertical-align: baseline;
                }
                &.active {
                    &:not(.ext-link) {
                        &::after {
                            background-color: var(--colour-primary);
                            content: "";
                            display: block;
                            height: 3px;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:991.98px) {
    .navbar {
        .navbar-nav {
            .nav-item {
                .nav-link {
                    @include convert-to-rem( 18 );
                    padding: 5px 0;
                    &.active {
                        &:not(.ext-link) {
                            color: var(--colour-primary);
                            font-weight: 700;
                            &::after {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

.navbar-light {
    .navbar-toggler {
        background-color: var(--colour-primary);
        color: var(--bg-colour);
        &:active, &:focus {
            box-shadow: none;
        }
    }
}